.m-scene .scene_element {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; 
}
.m-scene .scene_element--fadeindown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
.m-scene .scene_element--fadein {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; 
}
.m-scene .scene_element--fadeinup {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; 
}
.m-scene .fadeinleft {
  animation-name: fadeInLeft;
}
.m-scene .zoomin {
  animation-name: fadeInUp;
}
.m-scene .scene_element--fadeinright {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; 
}
.m-scene .scene_element--delayed {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s; 
}
.m-scene.is-exiting .scene_element {
  -webkit-animation-direction: alternate-reverse;
  animation-direction: alternate-reverse; 
}
.m-scene.is-exiting .scene_element--delayed {
  -webkit-animation-delay: 0s;
  animation-delay: 0s; 
}
.m-scene.is-exiting .scene_element--noexiting {
  -webkit-animation-direction: alternate-reverse;
  animation-direction: alternate-reverse; 
}
@media screen and (min-width: 768px) {
  .m-scene .scene_element--fadeinright-wide {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight; 
    } 
}

.is-loading,
.is-loading a {
  cursor: progress;
}