/* 	Glover Country Core Styles */

//                        .s$$$Ss.
//            .8,         $$$. _. .              ..sS$$$$$"  ...,.;
// o.   ,@..  88        =.$"$'  '          ..sS$$$$$$$$$$$$s. _;"'
//  @@@.@@@. .88.   `  ` ""l. .sS$$.._.sS$$$$$$$$$$$$S'"'
//   .@@@q@@.8888o.         .s$$$$$$$$$$$$$$$$$$$$$'
//     .:`@@@@33333.       .>$$$$$$$$$$$$$$$$$$$$'
//     .: `@@@@333'       ..>$$$$$$$$$$$$$$$$$$$'
//      :  `@@333.     `.,   s$$$$$$$$$$$$$$$$$'
//      :   `@33       $$ S.s$$$$$$$$$$$$$$$$$'
//      .S   `Y      ..`  ,"$' `$$$$$$$$$$$$$$
//      $s  .       ..S$s,    . .`$$$$$$$$$$$$.
//      $s .,      ,s ,$$$$,,sS$s.$$$$$$$$$$$$$.
//      / /$$SsS.s. ..s$$$$$$$$$$$$$$$$$$$$$$$$$.
//     /`.`$$$$$dN.ssS$$'`$$$$$$$$$$$$$$$$$$$$$$$.
//    ///   `$$$$$$$$$'    `$$$$$$$$$$$$$$$$$$$$$$.
//   ///|     `S$$S$'       `$$$$$$$$$$$$$$$$$$$$$$.
//  / /                      $$$$$$$$$$$$$$$$$$$$$.
//                           `$$$$$$$$$$$$$$$$$$$$$s.
//                            $$$"'        .?T$$$$$$$
//                           .$'        ...      ?$$#\
//                           !       -=S$$$$$s
//                         .!       -=s$$'  `$=-_      :
//                        ,        .$$$'     `$,       .|
//                       ,       .$$$'          .        ,
//                      ,     ..$$$'
//                          .s$$$'                 `s     .
//                   .   .s$$$$'                    $s. ..$s
//                  .  .s$$$$'                      `$s=s$$$
//                    .$$$$'                         ,    $$s
//               `   " .$$'                               $$$
//               ,   s$$'                              .  $$$s
//            ` .s..s$'                                .s ,$$
//             .s$$$'                                   "s$$$,
//          -   $$$'                                     .$$$$.
//        ."  .s$$s                                     .$',',$.
//        $s.s$$$$S..............   ................    $$....s$s......
//         `""'           `     ```"""""""""""""""
/*_
	Body
===========================*/
::selection {
    color: $white;
    background: $gc-khaki;
}
::-moz-selection {
	color: $white;
	background: $gc-khaki;
}
html * {
	@include font-smoothing;
}
body {
	background-color: $white;
}


/*_
	Header
===========================*/
header.primary {
	padding: rem-calc(15);
	background: $black;
	.columns {
		text-align: center;
	}
	p {
		color: $white;
	}
	.logo {
		@include breakpoint(medium) {
			max-width: 150px;
		}
	}
	.socials {
		@include breakpoint(medium) {
			text-align: right;
			img {
				margin: rem-calc(25 0 5 30);
			}
		}
		img {
			display: inline-block;
			margin: rem-calc(25 15 5 15);
			max-width: 25px;
		}
	}
}

/*_
	Primary nav
===========================*/
nav.primary {
	margin-bottom: rem-calc(30);
	padding: rem-calc(15);
	background: $gc-khaki;
	ul.menu {
		li {
			a {
				margin-right: rem-calc(20);
				color: $white;
				text-transform: uppercase;
				text-decoration: none;
				background: $gc-khaki;
				@include transition(all 0.2s ease);
				&:hover {
					color: $black;
					background: $white;
				}
			}
		}
	}
}

/*_
	Hero
===========================*/
.hero-wrapper {
	position: relative;
	margin: rem-calc(50 0 0);
	max-height: 600px;
	overflow: hidden;
	z-index: 5;
	.slick-slider {
		max-height: 600px;
	}
	.slick-slide {
		position: relative;
		outline: none;
		.img {
			width: 100%;
			height: auto;
			max-height: 600px;
			z-index: 1;
			img {
				width: 100%;

			}
			h1 {
				position: absolute;
				color: $white;
				text-align: center;
				width: 100%;
				span {
					font-size: rem-calc(30);
					font-weight: bold;
					text-transform: uppercase;
					@include breakpoint(large) {
						font-size: rem-calc(60);
					}
				}
				@include absolute-center();
				@include text-shadow(0, 0, 10px, rgba($black, 0.6));
			}
		}
		&.standard {
			max-height: 300px;
		}
	}
	.slick-arrow {
		z-index: 999;
		@include vertical-center();
	}
	.slick-prev,
	.slick-next {
		width: 30px;
		height: 30px;
		background: url('../img/hero-arrow.svg') no-repeat center center;
		&::before {
			display: none;
			content: "";
		}
	}
	.slick-prev {
		left: 0;
		@include transform-origin(50% 25%)
		@include rotate(180);
	}
	.slick-next {
		right: 0;
	}
	/*
	.dots-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 30px;
		@include horizontal-center;
	}
	.slick-dots {
		bottom: 0;
		z-index: 999;
		li {
			margin: rem-calc(0 2);
			button::before {
				color: $gc-beige;
				font-size: rem-calc(12);
			}
		}
	}
	*/
}

/*_
	Home intro
===========================*/
.home-intro {
	margin-top: rem-calc(30);
	padding: rem-calc(50 0);
	text-align: center;
	background: $gc-beige;
	h1 {
		span {
			font-weight: bold;
		}
	}
}

/*_
	Home boxes
===========================*/
.home-boxes {
	margin: rem-calc(100 0);
	text-align: center;
	.box {
		position: relative;
		margin-bottom: rem-calc(50);
		padding-bottom: rem-calc(50);
		img {
			margin-bottom: rem-calc(20);
		}
		h3 {
			padding-bottom: rem-calc(10);
			font-size: rem-calc(18);
			text-transform: uppercase;
			border-bottom: 2px solid $black;
		}
		p {
			font-size: rem-calc(14);
		}
		a.button {
			position: absolute;
			display: block;
			margin: 0;
			bottom: 0;
			width: 95%;
			text-transform: uppercase;
			background: $black;
			&:hover {
				background: $gc-khaki;
			}
			@include breakpoint (medium) {
				width: 92.3%;
			}
			@include breakpoint (large) {
				width: 90%;
			}
		}
	}
}

/*_
	Gallery
===========================*/
#lightgallery {
	margin: rem-calc(50 0 20);
	a {
		position: relative;
		display: inline-block;
		margin-bottom: rem-calc(15);
		&::before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			background: rgba($black, 0);
			@include transition(all 0.2s ease);
		}
		&:hover {
			&::before {
				background: rgba($black, 0.5);
			}
		}
		img {
			overflow: hidden;
		}
	}
}

/*_
	Home gallery link
===========================*/
.home-gallery {
	a {
		position: relative;
		display: block;
		max-height: 300px;
		overflow: hidden;
		&::before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			background: rgba($black, 0.75);
			@include transition(all 0.4s ease);
		}
		&:hover {
			&::before {
				background: rgba($black, 0);
			}
			h1::after {
				right: -45px;
			}
		}
	}
	h1 {
		position: absolute;
		color: $white;
		span {
			font-weight: bold;
			text-transform: uppercase;
		}
		@include text-shadow(0, 0, 10px, rgba($black, 0.6));
		@include absolute-center();
		&::after {
			position: absolute;
			right: -40px;
			content: '>';
			@include transition(all 0.2s ease);
		}
	}
	img {
		width: 100%;
		height: auto !important;
	}
}

/*_
	Standard content
===========================*/
section.primary {
	margin: rem-calc(50 0);
	min-height: 100vh;
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: underline;
		}
	}
}

/*_
	Blog + events
===========================*/
.blog,
.events {
	position: relative;
	margin: rem-calc(50 0 );
	h1 {
		font-weight: bold;
	}
	.event-item {
		margin: rem-calc(5 0 32);
		a {
			color: $black;
			@include breakpoint (large) {
				h3 {
					margin: 0;
					font-size: rem-calc(24);
					font-weight: bold;
				}
			}
		}
		.date {
			margin: rem-calc(0 0 10);
			color: $dark-gray;
			font-size: rem-calc(12);
		}
		.button {
			margin-top: rem-calc(10);
			padding: rem-calc(15 0);
			width: 100%;
			color: $white;
			font-size: rem-calc(16);
			text-transform: uppercase;
			background: $gc-khaki;
			&:hover {
				background: $black;
			}
		}

	}
	.item {
		margin: rem-calc(50 0);
		padding-bottom: rem-calc(25);
		border-bottom: 1px solid $light-gray;
		h2 {
			a {
				color: $black;
				&:hover {
					color: $gc-khaki;
				}
			}
		}
		.date {
			margin: rem-calc(-10 0 20 0);
			color: $dark-gray;
			font-size: rem-calc(14);
		}
		.button {
			text-transform: uppercase;
			background: $gc-khaki;
			&:hover {
				background: $black;
			}
		}
	}
}
.events {
	margin-bottom: rem-calc(50);
	.button-group {
		border-bottom: 2px solid $light-gray;
	}
	.button {
		background: $gc-khaki;
		&:hover {
			background: $black;
		}
	}
	.event-image {
		padding-top: rem-calc(100);
	}
}
.event-archive{
	margin-left: 0; margin-right: 0;
	.event-item{

		&:nth-child(2n+1){
			clear: both;
		}

		&.column:last-child:not(:first-child), &.columns:last-child:not(:first-child){
			float: left;
		}

		@include breakpoint (large) {
			&:nth-child(2n+1){
				clear: none;
			}
			&:nth-child(3n+1){
				clear: both;
			}
		}

		.event-title{
			@include breakpoint (large) {

				min-height: 5.125em;
			}

			h3{
				line-height: 1.1;
			}

		}
	}
}

/*_
	Sidebar
===========================*/
.sidebar {
	margin-top: rem-calc(80);
	padding: rem-calc(10 15);
	background: rgba($gc-beige, 0.4);
	h3 {
		margin-bottom: rem-calc(20);
		border-bottom: 2px solid $black;
	}
	p {
		font-size: rem-calc(14);
	}
	ul {
		li {
			margin: rem-calc(5 0);
			p {
				margin: 0;
			}
		}
	}
}

/*_
	Contact
===========================*/
.contact-form {
	margin: rem-calc(20 0);
	label {
		font-size: rem-calc(18);
		font-weight: bold;
	}
	input, textarea, select {
		font-weight: normal;
	}
	.button {
		float: right;
		padding: rem-calc(10 30);
		font-size: rem-calc(18);
		font-weight: bold;
		text-transform: uppercase;
		background: $gc-khaki;
		&:hover {
			background: $black;
		}
	}
	#LastName {
		display: none;
	}
}

/*_
	Footer
===========================*/
footer {
	margin-top: rem-calc(30);
	text-align: center;
	background: $gc-khaki;
	p {
		margin: 0;
		padding: rem-calc(10 0 0);

		color: $white;
		font-size: rem-calc(12);
		@include breakpoint (medium) {
			padding: rem-calc(15 0);
			text-align: left;
		}
	}
	a {
		color: $white;
		text-decoration: none;
		&:hover {
			color: $gc-beige;
		}
	}
	.atm {
		margin-bottom: rem-calc(10);
		a {
			color: $black;
			font-size: rem-calc(12);
			font-weight: bold;

			span {
				font-weight: normal;
			}
		}
		@include breakpoint(medium) {
			padding-top: rem-calc(10);
			text-align: right;
		}
	}
}

.event-brite{
	clear: both;
	padding-top: 2em;
	padding-bottom: 2em;
}
